<template>
    <div class="fondo-degradado-recover"></div> 
    <div class="contenedor-login d-flex align-items-center justify-content-center flex-column">
        <h1 class="titulo-login">{{objectTextLenguage['¡Hola de nuevo!'][language]}}</h1>
        <h2 class="subtitulo-login">{{objectTextLenguage['Inicia sesión con tu correo electrónico.'][language]}}</h2>
        <br>
        <FormCustom 
            :typeContainer="typesContainer.space"
            :objectForm="[
                {
                    type:'text',
                    label: 'Email',
                    name: 'email',
                    width: '350px',
                    widthComplete: true,
                    required: true,
                },
                {
                    type:'password',
                    label: objectTextLenguage['Contraseña'][language],
                    name: 'password',
                    width: '350px',
                    widthComplete: true,
                    required: true
                },                        
            ]"
            :actionSecButton="(data)=>this.loginNormal(data)"
            textSecButton="Login"
            typeStyle="column"
            :isOneButton="true"
        >
            <template v-slot:contentBeforeButton>
                <div class="width-complete" style="
                    padding-right: 0.75rem;
                    padding-left: 0.75rem; 
                ">
                    <button 
                        class="button-login-style" 
                        @click="loginGoogle"
                    >
                        <div></div>
                        <p>{{objectTextLenguage['Continuar con google'][language]}}</p>
                    </button>
                </div>
                <p class="recupera-login">
                    <router-link to="/register" class="link-login align-self-center">
                        {{objectTextLenguage['Registrarme'][language]}}
                    </router-link>
                </p>
                <p class="recupera-login">
                    {{objectTextLenguage['Recupera tu contraseña '][language]}} 
                    <router-link to="/recover" class="link-login">
                        {{objectTextLenguage['aquí'][language]}}
                    </router-link>
                </p>
            </template>
        </FormCustom>
        <div class="error-login d-flex justify-content-center align-items-center" v-if="this.stateLogin.success === 'error'">
            <div><p>{{mensaje}}</p></div>
        </div>  
        <div class="correcto-login d-flex justify-content-center align-items-center" v-if="!this.isSesionActive &&this.stateLogin.success === 'ok'">
            <div><p>{{mensaje}}</p></div>
        </div>
    </div>
    
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import {mapState, mapActions} from "vuex"
    import axios from 'axios'
    import {swift} from '../../helpers/swift'
    import {quitarError, quitarHecho} from '../../helpers/quitarAvisosForm'
    import {objectTextLenguage} from '../../helpers/objectTextLenguage'
    import FormCustom from '../../components/FormCustom.vue'
    import { googleTokenLogin } from "vue3-google-login"
    import typesContainer from "../../types/typesContainer"

    export default {
        name: "Login",
        components: {
            Footer,
            FormCustom,
        },
        data(){
            return{
                //tipos

                typesContainer,

                //control de respuestas de la api

                mensaje:'',

                //lenguaje

                objectTextLenguage: objectTextLenguage
            }
        },
        methods: {
            ...mapActions([
                "login",
                "loginWithGoogle",
                "sesionActive",
                "sesionInactive",
                "clearStateLogin"
            ]),
            
            //funcion encargada del inicio de sesion normal

            async loginNormal(data){

                //destructuring
                const {email,password} = data

                //consulta con la api
                await this.login({email,password})

                //el correo no esta registrado

                if(this.stateLogin.message === "el usuario no existe"){
                    this.swift('Correo invalido',1)
                }

                //inputs en blanco

                if(this.stateLogin.message === "porfavor envia tu password y email"){
                    this.swift('porfavor envia tu password y email',1)
                }

                //contrasenia invalida

                if(this.stateLogin.message === "Uno o mas campos son incorrectos"){
                    this.swift('Clave incorrecta',1)
                }

                //logeo exitoso

                if(this.stateLogin.success === "ok"){
                    localStorage.setItem('token', this.stateLogin.message)
                    this.swift('Registro exitoso',2)
                }
            },

            //login por google

            async loginGoogle() {
                // inicio de sesion con vuex
                await this.loginWithGoogle(googleTokenLogin)

                //el usuario existe, ingreso exito
                if(this.stateLogin.success === "ok"){
                    //confirmacion de token con api
                    await axios.post(`${this.server}/information/confirmarGoogle`, {
                        token: this.stateLogin.message
                        //pensar en posibilidad de que token quede como estado global
                    })
                    //inicio de sesion
                    localStorage.setItem('token', this.stateLogin.message)
                    this.swift("Registro exitoso",2)
                }else{
                    //el usuario no existe, registrate primero
                    this.swift("no existe el usuario, registrate por favor",1)
                }
            },

            //funcion encargada de quitar la caja de error
            quitarError,

            //funcion encargada de quitar la caja de correcto
            quitarHecho,

            //swift entre error y correcto
            swift,
        },
        created(){
            this.sesionInactive()
        },
        mounted(){
            scrollTo(0,0)
            this.$forceUpdate()
        },
        unmounted(){
            clearTimeout(this.timeOut)
        },
        computed:{          
            ...mapState(["token", "server", "stateLogin", "isSesionActive", 'language']),
        }
    }
</script>

<style scoped>
    .recupera-login{
        font-family: Rubik;
        font-weight: bold;
    }
    .button-login-style{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius:10px;
        width: 325px;
        border: #6464646b 2px solid;
        height: 36px;
        background: white;
        margin-bottom: 10px;
    }
    .button-login-style div{
        width: 20px;
        height: 20px;
        background-image:url('https://rotulosmatesanz.com/wp-content/uploads/2017/09/2000px-Google_G_Logo.svg_.png') ;
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }
    .button-login-style p{
        margin-left: 13px;
        height: 10px;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
    }
    .width-complete{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .error-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-login{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .fondo-degradado-recover{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        z-index: -10;
        height: 100vh;
    }
    .titulo-login{
        font-family: Rubik;
        font-weight: bold;
        margin-top:80px ;
        font-size: 55px;
    }
    .subtitulo-login{
        font-family: Rubik;
        margin-top:0px ;
        font-weight: bold;
        font-size: 25px;
    }
    .link-login{
        width: 100px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-top: 25px;
        color: #297F87;
        text-decoration: none;
    }
</style>