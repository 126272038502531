export const objectTextLenguage = {
    '¡Hola de nuevo!':{
        esp: '¡Hola de nuevo!',
        ing: '¡Welcome back!'
    },
    'Inicia sesión con tu correo electrónico.':{
        esp: 'Inicia sesión con tu correo electrónico.',
        ing: 'Log in with your email address.'
    },
    'Contraseña':{
        esp: 'Contraseña',
        ing: 'Password'
    },
    'Continuar con google':{
        esp: 'Continuar con google',
        ing: 'Continue with google'
    },
    'Registrarme':{
        esp: 'Registrarme',
        ing: 'Register'
    },
    'Recupera tu contraseña ':{
        esp: 'Recupera tu contraseña ',
        ing: 'Recover your password '
    },
    'aquí': {
        esp: 'aquí',
        ing: 'here'
    }
}